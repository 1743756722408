<template>
    <div>
        <h1>404</h1>
        <p>Oops!!</p>
        <router-link to="/">Home</router-link>
    </div>
</template>
<script>
export default {
    name: 'NotFound'
}
</script>